/* eslint-disable promise/no-nesting */
/* eslint-disable camelcase */
/* eslint-disable promise/always-return */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import {
  TextInput,
  Password,
  PrimaryButton,
  RenderPasswordCondition,
  Text,
} from '../../Components'
import Loading from 'react-lottie'
import Recaptcha from 'react-google-recaptcha'
import { loginAnimation, GradientLogo, loadingDataplayer } from '../../Themes/Images'
import { useFormSignup } from '../../Utils/validation'
import { useAuth } from '../../Global'
import { Auth } from 'aws-amplify'
import { Link, Redirect } from 'react-router-dom'
import { colors } from '../../Themes'
import { Checkbox } from '@material-ui/core'
import DifferentPracticeManagement from '../DifferentPracticeManagement/DifferentPracticeManagement'
import { useWindowDimensions } from '../../Utils'
import { SelectInput } from '../../Components/Input'
import { useStyles, selectStyles } from './Signup.styles'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import PhoneNumberInput from '../../Components/Input/PhoneNumberInput'
import { isValidPhoneNumber } from 'react-phone-number-input'

const LOADING_OPTIONS = {
  loop: true,
  autoplay: true,
  animationData: loginAnimation,
  rendererSettings: {
    className: 'loginAnim',
  },
}

const SELECT_OPTIONS = [
  { value: 'Cliniko', label: 'Cliniko' },
  { value: 'Nookal', label: 'Nookal' },
  { value: 'practicehub', label: 'PracticeHub' },
  { value: 'Gensolve', label: 'Gensolve' },
  { value: 'Other', label: 'Other' },
]

const VisitDataplayer = () => {
  const classes = useStyles()
  return (
    <div className={classes.visitDataplayerContainer}>
      <Text weight="medium" color={colors.white} > Want to learn more ?</Text>
      <PrimaryButton
        className={classes.visitDataplayerMobileButton}
        onClick={() => window.open('https://dataplayer.io/')}
      >
        Visit Dataplayer
      </PrimaryButton>
    </div>
  )
}

const re = {
  lowerCase: /.*[a-z].*/,
  capital: /[A-Z]+/,
  digit: /[0-9]+/,
  symbols: /[-!$%^#@&*()_+|~=`{}\[\]:";'<>?,.\/]+/,
}

function Signup() {
  const classes = useStyles()
  const { isAuthenticated, getAuthenticatedUser, shouldLogin, setShouldLogin, login } = useAuth()
  const { width } = useWindowDimensions()

  const reRef = useRef()
  const [recaptcha, setRecaptcha] = useState(false)
  const [loading, setLoading] = useState(false)

  const [values, setValues] = useState({
    username: '',
    email: '',
    clinicName: '',
    pms: '',
    phone_number: '',
    other: '',
    'custom:tenant': 'dataplayer'
  })
  const [TermsChecked, setTermChecked] = useState({
    isChecked: false,
    error: '',
  })

  const [password, setPassword] = useState('')


  const [validatePassowrd, setValidatePassword] = useState({
    minimumLength: false,
    number: false,
    upperCase: false,
    lowerCase: false,

    symbols: false,
    focused: false,
    isVisible: false,
  })
  const [error, setError] = useState()
  const [disabled, setDisabled] = useState(false)

  const [phoneError, setphoneError] = useState('')


  const onChangePassword = (text) => {
    setValidatePassword((prev) => ({
      ...prev,
      upperCase: re.capital.test(text),
      lowerCase: re.lowerCase.test(text),

      number: re.digit.test(text),
      symbols: re.symbols.test(text),
      minimumLength: text.length >= 8 && text.length < 99,
    }))

    setPassword(text)
  }

  // const login = () => {
  //   Auth.signIn(values?.username, password)
  //     .then((user) => {
  //       getAuthenticatedUser(user)
  //       setLoading(false)

  //     })
  //     .catch((err) => {
  //       setError('Opps Something went wrong!')
  //       setLoading(false)

  //     })
  // }

  const username = values?.username

  const number = '+355333333333'

  const history = useHistory()
  const toVerify = () => history.push({
    pathname: '/verify',
    state: { username, password }
  })

  const loginUser = () => {
    login(values.username.trim(), password.trim(), toVerify)
  }

  const signup = () => {
    if (!TermsChecked.isChecked) {
      setLoading(false)
      setTermChecked((prev) => ({
        ...prev,
        error:
          'You must accept Dataplayer\'s Terms of Use and Privacy Policy in order to create your account. ',
      }))
    } else {
      const { username, clinicName, email, pms } = values
      Auth.signUp({
        username,
        password,
        attributes: {
          email: email.trim(),
          nickname: clinicName,
          given_name: pms?.value,
          phone_number: values?.phone_number,
          'custom:tenant': 'dataplayer'
        },
      })
        .then((user) => {
          loginUser()
        })
        .catch((err) => {
          setLoading(false)
          if (err?.code === 'UserLambdaValidationException') {
            console.log(err)
            setErrors({ email: 'This email address is already being used. ' })
          } else if (err?.code === 'UsernameExistsException') {
            console.log(err)
            setErrors({ username: 'This username  is already being used. ' })
          } else if (!navigator.onLine) {
            setError('Please check you internet connection and try again!')
          }
          else {
            console.log(err)
            setError(
              'Sorry, your last action could not be completed. But don’t worry, not all is lost. Check your internet connection, if the problem persists, please contact us.'
            )
          }
        })
    }
  }


  const onSubmit = () => {
    // if practice management software is not what we offer
    setLoading(true)

    if (values?.pms?.value === 'Other') {
      let body = {
        name: values.username,
        clinic_name: values.clinicName,
        practice_management_software: values?.other,
        email: values.email,
        phone_number: values.phone_number,
        source: 'Register Form',
        'custom:tenant': 'dataplayer'
      }
      axios({
        method: 'POST',
        url: 'https://uphzr75ztc.execute-api.ap-southeast-2.amazonaws.com/prod/leads',
        data: body,
      }).then(
        (res) => {
          setLoading(false)
          setShouldLogin(false)

        }).catch(e => {
          setLoading(false)
        })
    } else if (values?.pms?.value === 'Gensolve') {
      let body = {
        name: values.username,
        clinic_name: values.clinicName,
        practice_management_software: values?.pms?.value,
        email: values.email,
        phone_number: values.phone_number,
        source: 'Register Form',
        'custom:tenant': 'dataplayer'
      }
      axios({
        method: 'POST',
        url: 'https://uphzr75ztc.execute-api.ap-southeast-2.amazonaws.com/prod/leads',
        data: body,
      }).then(
        (res) => {
          setLoading(false)
          setShouldLogin(false)

        }).catch(e => {
          setLoading(false)
        })
    }
    else {
      signup()
    }
  }

  const {
    errors,
    setErrors,
    handleChange,
    handleSubmit,
    handleOnBlur,
  } = useFormSignup(onSubmit, setValues)

  useEffect(() => {
    if (error) {
      setError(undefined)
    }
  }, [values?.username, password])


  useEffect(() => {
    if (
      Object.keys(errors).length === 0 &&
      phoneError === '' &&
      values.username !== '' &&
      values.clinicName !== '' &&
      values.email !== '' &&
      password !== '' &&
      values.pms !== '' &&
      values.phone_number !== '' &&
      recaptcha &&
      validatePassowrd.minimumLength &&
      validatePassowrd.number &&
      validatePassowrd.symbols &&
      validatePassowrd.upperCase
      &&
      validatePassowrd.lowerCase
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [
    values?.username,
    values?.clinicName,
    values?.email,
    values?.pms,
    values?.phone_number,
    phoneError,
    recaptcha,
    password,
    errors,
    validatePassowrd,
  ])

  const [openTooltip, setOpenTooltip] = useState(false)

  const handleClose = () => {
    setOpenTooltip(false)
  }

  const handleOpen = () => {
    setOpenTooltip(true)
  }
  if (!shouldLogin) {
    return <DifferentPracticeManagement value={values?.pms?.value} />
  }

  if (isAuthenticated) {
    return <Redirect
      exact
      to={{
        pathname: '/dashboard',
        state: { fromLogin: true }
      }}
    />
  }

  const handlePhoneBlur = () => {
    if (!values?.phone_number) {
      setphoneError('Please fill out the required information.')
    }
    // else if (values?.phone_number && !isValidPhoneNumber(values?.phone_number))
    // {
    //   setphoneError('Invalid number format.')
    // }
    else {
      setphoneError('')
    }
  }

  const handleChangePhone = (e) => {
    setphoneError(e.target.value)
    setphoneError('')
  }

  return (
    <>
      <div className={classes.loginContainer}>
        <div className={classes.loginAnimationContainer}>
          <h1 className={classes.loginTitle}>INTELLIGENT INSIGHTS</h1>
          <Loading options={LOADING_OPTIONS} height={500} />
          <PrimaryButton
            className={classes.visitDataplayerButton}
            onClick={() => window.open('https://dataplayer.io/')}
          >
            Visit Dataplayer
          </PrimaryButton>
        </div>
        <form className={classes.loginForm} onSubmit={handleSubmit}>
          <div className={classes.loginWrapper}>
            <GradientLogo className={classes.gradientLogo} />
            <h1 className={classes.title}>Create an account</h1>
            <TextInput
              // classes={{root:classes.inputRoot}}
              costumizedInputStyles={
                classes['input_' + Boolean(errors?.username)]
              }
              error={Boolean(errors?.username)}
              name="username"
              label="Username"
              value={values?.username || ''}
              setValue={(value) => handleChange(value, 'username')}
              onBlur={(e) => handleOnBlur(e)}
              inputLabelStyle={{ fontSize: 16 }}
            />
            {errors?.username && (
              <p className={classes.errorInput}>{errors?.username}</p>
            )}
            <TextInput
              classes={{ root: classes.inputRoot }}
              costumizedInputStyles={classes['input_' + Boolean(errors?.email)]}
              error={Boolean(errors?.email)}
              label="Email"
              name="email"
              value={values?.email || ''}
              setValue={(value) => handleChange(value, 'email')}
              onBlur={(e) => handleOnBlur(e)}
              inputLabelStyle={{ fontSize: 16 }}
            />
            {errors?.email && (
              <p className={classes.errorInput}>{errors?.email}</p>
            )}
            <Text size='small' color='#5C5C5C' weight="light" style={{ margin: '8px 0', display: 'block' }} > <b>Note: </b>This is where you&apos;ll get your reports, make sure it&apos;s an email you use.</Text>
            {/* <TextInput
              // classes={{root:classes.inputRoot}}
              costumizedInputStyles={
                classes['input_' + Boolean(errors?.phone_number)]
              }
              error={Boolean(errors?.phone_number)}
              name="phone_number"
              label="Phone number"
              value={values?.phone_number || ''}
              setValue={(value) => handleChange(value, 'phone_number')}
              onBlur={(e) => handleOnBlur(e)}
              inputLabelStyle={{ fontSize: 16 }}
            /> */}
            {/* {errors?.phone_number && (
              <p className={classes.errorInput}>{errors?.phone_number}</p>
            )} */}

            {/* {(values?.phone_number && !isValidPhoneNumber(values?.phone_number)) && 
            <p className={classes.errorInput}>Invalid number format</p> } */}
            <TextInput
              // classes={{root:classes.inputRoot}}
              costumizedInputStyles={
                classes['input_' + Boolean(errors?.clinicName)]
              }
              error={Boolean(errors?.clinicName)}
              name="clinicName"
              label="Clinic name"
              value={values?.clinicName || ''}
              setValue={(value) => handleChange(value, 'clinicName')}
              onBlur={(e) => handleOnBlur(e)}
              inputLabelStyle={{ fontSize: 16 }}
            />
            {errors?.clinicName && (
              <p className={classes.errorInput}>{errors?.clinicName}</p>
            )}
            <PhoneNumberInput
              value={values?.phone_number || ''}
              setValue={(value) => {
                handleChange(value, 'phone_number')
                if (phoneError !== '') { setphoneError('') }
              }}
              signup={true}
              onBlur={handlePhoneBlur}
              name='phone_input'
            // errorNumber={values?.phone_number && !isValidPhoneNumber(values?.phone_number) ? 'true' : 'false'}
            />
            {phoneError &&
              <p className={classes.errorInput}>{phoneError}</p>}
            <Text size='footnote' color='#5C5C5C' weight="bold" style={{ display: 'block', marginTop: 20 }}>Practice Management Software</Text>
            <div className={classes.dropwdownWrapper}>
              <SelectInput
                value={values?.pms}
                selectCostumStyles={selectStyles}
                label="Practice Management Software"
                placeholder="PMS"
                options={SELECT_OPTIONS}
                onChange={(value) => handleChange(value, 'pms')}
              />
              {values?.pms?.value === 'Other' &&
                <TextInput
                  costumizedInputStyles={
                    [classes['other'], classes['input_' + Boolean(errors?.other)]]
                  }
                  name="other"
                  label="Enter PMS name"
                  value={values?.other || ''}
                  setValue={(value) => handleChange(value, 'other')}
                  onBlur={(e) => handleOnBlur(e)}
                  error={Boolean(errors?.other)}
                  inputLabelStyle={{ fontSize: 16 }}
                />}
            </div>
            {/* <TextInput
              // classes={{root:classes.inputRoot}}

              costumizedInputStyles={
                classes['input_' + Boolean(errors?.pms)]
              }
              error={Boolean(errors?.pms)}
              name="pms"
              label="Practice Management Software"
              value={values?.pms || ''}
              setValue={(value) => handleChange(value, 'pms')}
              onBlur={(e) => handleOnBlur(e)}
              endAdornment={
                <InputAdornment>
                  <Tooltip open={openTooltip} onClose={handleClose} onOpen={handleOpen} title="Make sure you enter the name correctly.">
                    <IconButton onClick={handleOpen}>
                      <InfoOutlined />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>

              }
            /> */}
            {errors?.pms &&
              <p className={classes.errorInput}>{errors?.pms}</p>
            }
            {errors?.other &&
              <p className={classes.errorInputEnd}>{errors?.other}</p>
            }

            <Password
              // classes={{root:classes.inputRoot}}
              label="Password"
              value={password}
              setValue={onChangePassword}
              onFocus={() =>
                setValidatePassword((prev) => ({
                  ...prev,
                  focused: true,
                  isVisible: true,
                }))
              }
              onBlur={() =>
                setValidatePassword((prev) => ({
                  ...prev,
                  focused: false,
                }))
              }
              inputLabelStyle={{ fontSize: 16 }}
            />
            {validatePassowrd.isVisible && (
              <RenderPasswordCondition validatePassowrd={validatePassowrd} />
            )}

            <div className={classes.tearmsAndCondition}>
              <Checkbox
                checked={TermsChecked.isChecked}
                onChange={(event) => {
                  setTermChecked({
                    error: '',
                    isChecked: event.target.checked,
                  })
                }}
              />
              <p
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 400,
                }}
              >
                I accept Dataplayer’s <a className={classes.link} target="_blank" rel="noopener noreferrer" href="https://www.dataplayer.io/terms" >Terms of Use</a>  and <a className={classes.link} target="_blank" rel="noopener noreferrer" href="https://www.dataplayer.io/security"> Privacy Policy </a>.
              </p>
            </div>
            {TermsChecked?.error && (
              <p className={classes.errorInput}>{TermsChecked?.error}</p>
            )}
            <Recaptcha
              sitekey="6Lf4PHEaAAAAAPV81vIGS8kA0gOPfXNpCS6GgUd4"
              size="normal"
              ref={reRef}
              onChange={(value) => {
                if (value) setRecaptcha(true)
              }}
            />
            {Boolean(error) && <span className="loginError">{error}</span>}

            <div className={classes.loginButtons}>

              <div style={{ position: 'relative', width: '100%' }}>
                <PrimaryButton
                  type="submit"
                  // disabled={Object.keys(errors).length !== 0}
                  disabled={disabled || loading}
                  loading={loading}
                  style={
                    disabled || loading
                      ? { backgroundColor: colors.disabled, color: colors.white, minHeight: 50 }
                      : { backgroundColor: colors.primary, minHeight: 50 }
                  }
                  className={classes.loginButton}
                >
                  Create account
                </PrimaryButton>

              </div>
              <div className={classes.infoWrapper} >
                <p className={classes.infoText}>
                  You already have an account?
                </p>
                <Link className={classes.forgotPassword} to="/login">
                  <span style={{ fontWeight: 'bold' }}>Log in</span>
                </Link>
              </div>
            </div>
          </div>

        </form>

      </div>
      {width < 960 && <VisitDataplayer />
      }
    </>
  )
}

export default Signup
